<template>
  <div class="supportContant">
    <div class="container">
      <div class="nav">
        <div class="auto-container">
          <p>
            <a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />
            <a href="/support">支持</a>
            <Icon class="arrow" type="ios-arrow-forward" />{{ menu.title }}
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <!-- 标题 -->
          <h2 class="title">{{ content.title }}</h2>
          <!-- 正文 -->
          <div class="content" v-html="content.contents"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supportContant from "./index.js";
export default supportContant;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

<style>
@media screen and (max-width: 768px) {
  .content img {
    width: 100%;
  }
}
</style>